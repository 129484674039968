import React from "react"
import iconDigidombes from "../images/icon-digidombes.png"
import { Helmet } from 'react-helmet'
import '../css/bootstrap.min.css'
import '../css/material.min.css'
import '../css/style.css'

export default function Layout({ children, title, description }) {
  return (

    <div>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <script defer src="https://code.getmdl.io/1.3.0/material.min.js"></script>
      </Helmet>

            <div className="container accueil">
              {children}
                <footer className="row">
                    <div className="col text-center">
                            <p>PROFESSIONNEL <b>.</b> ECONOMIQUE <b>.</b> DESIGN</p>
                        <p className="signature"> <span className="red">digiDombes</span> artisans du web</p>
                        <img src={iconDigidombes}/>
                    </div>
                </footer>
            </div>
            </div>
  )
}